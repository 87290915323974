import {
    NAV_ITEM_TYPE_TITLE,
    NAV_ITEM_TYPE_COLLAPSE,
    NAV_ITEM_TYPE_ITEM,NAV_ITEM_TYPE_CUSTOM
} from 'constants/navigation.constant'
import { APP_PREFIX_PATH } from 'constants/route.constant'

const mainNavConfig = [
    {
        key: 'products',
        path: "#",
        match: APP_PREFIX_PATH+'/products/clothing/all',
        path: APP_PREFIX_PATH+'/products/clothing/all',
        title: 'Catalog',
        translateKey: 'Catalog',
        icon: '',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
    },
    {
        key: 'howitworks',
        path: "#",
        match: APP_PREFIX_PATH+'/products/clothing/all',
        path: APP_PREFIX_PATH+'/products/clothing/all',
        title: 'How it works?',
        translateKey: 'How it works?',
        icon: '',
        type: NAV_ITEM_TYPE_COLLAPSE,
        authority: [],
        subMenu: [
            {
                key: 'howitworks.item1',
                path: APP_PREFIX_PATH+'/fulfillment-services/',
                title: 'Print on Demand solutions',
                translateKey: 'Print on Demand solutions',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            
            {
                key: 'howitworks.item3',
                path: 'https://usprintfactory.com/app/sign-up?redirectUrl=/dashboard/team/shop',
                title: 'USPF for Team',
                translateKey: 'USPF for Team',
                targetOut:"_blank",
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            // {
            //     key: 'product.catalogue',
            //     path: APP_PREFIX_PATH+'/products/catalogue/',
            //     title: 'Product Catalogue',
            //     translateKey: 'Product Catalogue',
            //     icon: '',
            //     type: NAV_ITEM_TYPE_ITEM,
            //     authority: [],
            //     subMenu: [],
            // }
           
        ],
    },
    // {
    //     key: 'services',
    //     path: "#",
    //     match: APP_PREFIX_PATH+'/fulfillment-services/',
    //     title: 'Services',
    //     translateKey: 'Services',
    //     icon: '',
    //     type: NAV_ITEM_TYPE_COLLAPSE,
    //     authority: [],
    //     subMenu: [
    //         {
    //             key: 'services.item1',
    //             path: APP_PREFIX_PATH+'/fulfillment-services/',
    //             title: 'Why USPF',
    //             translateKey: 'Why USPF',
    //             icon: '',
    //             type: NAV_ITEM_TYPE_ITEM,
    //             authority: [],
    //             subMenu: [],
    //         },
           
    //     ],
    // },
    
   
    {
        key: 'integration',
        path: "#",
        match: APP_PREFIX_PATH+'/connect/',
        title: 'Integration',
        translateKey: 'Integration',
        icon: '',
        type: NAV_ITEM_TYPE_COLLAPSE,
        authority: [],
        subMenu: [
            {
                key: 'integration.item1',
                path: APP_PREFIX_PATH+'/connect/shopify',
                title: 'Shopify',
                translateKey: 'Shopify',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'integration.item2',
                path: APP_PREFIX_PATH+'/connect/etsy',
                title: 'Etsy',
                translateKey: 'Etsy',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'integration.item3',
                path: APP_PREFIX_PATH+'/connect/woocommerce',
                title: 'WooCommerce',
                translateKey: 'WooCommerce',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'integration.item4',
                path: APP_PREFIX_PATH+'/connect/amazon',
                title: 'Amazon',
                translateKey: 'Amazon',
                icon: '',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
        ],
    },
    // {
    //     key: 'pricing',
    //     path: APP_PREFIX_PATH+'/pages/pricing',
    //     match: APP_PREFIX_PATH+'/pages/pricing',
    //     title: 'Pricing',
    //     translateKey: 'Pricing',
    //     icon: '',
    //     type: NAV_ITEM_TYPE_TITLE,
    //     authority: [],
    //     subMenu: [],
    // },
    {
        key: 'whyuspf',
        match: APP_PREFIX_PATH+'/why-uspf',
        path: APP_PREFIX_PATH+'/why-uspf',
        title: 'Why USPF?',
        translateKey: 'Why USPF? ',
        icon: '',
        type: NAV_ITEM_TYPE_TITLE,
        authority: [],
        subMenu: [],
    },
    {
        key: 'helpdesk',
        match: APP_PREFIX_PATH+'/uspf-helpdesk',
        path: APP_PREFIX_PATH+'/uspf-helpdesk',
        title: 'Helpdesk',
        translateKey: 'Helpdesk',
        icon: '',
        type: NAV_ITEM_TYPE_TITLE,
        authority: [],
        subMenu: [],
    },
   
    
]

export default mainNavConfig
