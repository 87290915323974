import { Button, Input, InputGroup } from 'components/ui'
import { APP_PREFIX_PATH } from 'constants/route.constant'
import React from 'react'
import { Link } from 'react-router-dom'
const { Addon } = InputGroup
const MainHeroSectionSearch = (props) => {
  return (
    <>
      <section className="md:pt-[120px] pt-[120px] pb-20 md:pb-[20px] bg-uspfredlow relative">
        <div className="container">
          <div className="flex flex-col items-center justify-center space-y-4">
            <h1 className="font-bold text-3xl">{props.title}</h1>
            <p className="mt-3">
               <InputGroup className="flex items-center"><Input placeholder="Search within help center..." className="w-[32px] md:w-[500px]" />
               <Button>Search</Button></InputGroup>
            </p>
           
          </div>
        </div>
      </section>
    </>
  )
}

export default MainHeroSectionSearch
