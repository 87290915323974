import React from "react";
import FooterOne from "../components/footers/FooterOne";
import FAQ from "../features/common/FAQ";
import SmartFeature from "../features/common/SmartFeature";
import MainHeroSection from "../features/common/MainHeroSection";
import FooterTwo from "components/footers/FooterTwo";
import MainHeroSectionSearch from "features/common/MainHeroSectionSearch";

const FAQPage = () => {
  return (
    <>
      <MainHeroSectionSearch title="Explore informative articles, videos, and additional resources!" subTitle="Want To Ask Something From Us?" />
      {/* <SmartFeature/>  */}
      <FAQ/>
      <FooterTwo/>
    </>
  );
};

export default FAQPage;
