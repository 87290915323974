import React from "react";
import FooterOne from "../components/footers/FooterOne";
import FAQ from "../features/common/FAQ";
import useQuery from "utils/hooks/useQuery";
import { useParams } from "react-router-dom";
import { useEffect,useState } from "react";
import { API_PREFIX_PATH } from "constants/route.constant";
import axios from "axios";
import Loading from "components/shared/Loading";
import MainHeroSection from "../features/common/MainHeroSection";
import FooterTwo from "components/footers/FooterTwo";
import MainHeroSectionSearch from "features/common/MainHeroSectionSearch";
import ReactHtmlParser from 'react-html-parser';
const HelpArticle = () => {
    const params = useParams();
    const [article, setarticle] = useState(null);
    const [loading, setloading] = useState(true)
    useEffect(() => {
      getArticle()
    
      return () => {
        
      }
    }, [])

    const getArticle = async()=>{
        
        if(params.slug){
            const list = await axios.post(`${API_PREFIX_PATH}help/article/get?twitter=true`, {slug:params.slug}, {
                headers: {
                    'Content-Type': 'application/json'
                    
                },
                }); 
                setarticle(list.data.article)
                setloading(false)
        }
        
    }
    
  return (
    <>
     <Loading loading={loading}>
      {
        article &&  <><MainHeroSection title={article.title}  subTitle={""} hidebtn={true} />
         <section className="pt-10 pb-[170px] bg-light">
        <div className="container">
         
          
            <div className="mx-auto w-full ">
        <div>{ ReactHtmlParser (article.content)}</div>
        
        </div>
        </div></section>
        </>
        

      }
      </Loading>
      <FooterTwo/>
    </>
  );
};

export default HelpArticle;
